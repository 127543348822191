import React from 'react'

// function addSpacesAfter(no, pl) {
//   return no.split('').reduce((out, v, k) => {
//     if (pl.includes(k + 1) && (k + 1) < no.length) {
//       return out + v + ' '
//     }
//     return out + v
//   }, '')
// }

function CodeInput({code, onChange}) {
  function handleChange(event) {
    let value = event.target.value
    value = value.replace(/[\W]/g, '')
    value = value.toUpperCase()
    value = value.substring(0, 6)
    let valid = value.length === 6
    let raw = value
    // value = addSpacesAfter(value, [3])
    onChange({value, valid, raw})
  }

  return (
    <input
      className="CodeInput"
      value={code.value}
      onChange={handleChange}
      name="code"
      type="text"
      id="code"
      required
    />
  );
}


export default CodeInput
