import React, {useState} from 'react'
import './login-page.scss'
import PhoneInput from '../phone-input'
import {sendTextToken, login} from '../../api'
import CodeInput from "../code-input"


function LoginPage({newToken}) {
  const [phone, setPhone] = useState({
    no: "+48",
    raw: "+48",
    valid: false
  })

  const [codeSent, setCodeSent] = useState(false)
  const [code, setCode] = useState({
    value: "",
    raw: "",
    valid: false
  })


  function handleSubmitText(e) {
    e.preventDefault();
    sendTextToken(phone.raw)
    setCodeSent(true)
  }

  function handleSubmitLogin(e) {
    e.preventDefault();
    login(phone.raw, code.raw).then((token) => {
      newToken(token)
    })

  }

  return (
    <div className="LoginPage">
      <div className="left-box"/>
      <div className="right-box">
          <h2 className="heading">KAMILA <i className=" icon-heart-empty pink-heart"/> BARTEK</h2>
        <div className="date">- 26 LIPIEC 2019 -</div>
        <div className="login-box">


          <form onSubmit={handleSubmitText}>
            <label htmlFor="tel" className="label">Twój numer telefonu</label>
            <div className="formLiner">
              <PhoneInput onChange={setPhone} phone={phone} disabled={codeSent} name="tel"/>
              <input
                type="submit"
                className={"submit"}
                disabled={!phone.valid || codeSent}
                value="Wyślij hasło"
              />
            </div>

          </form>
          {codeSent &&
          <form onSubmit={handleSubmitLogin}>
            <label htmlFor="code" className="label">Hasło z wiadomości</label>
            <div className="formLiner">
              <CodeInput onChange={setCode} code={code}/>
              <input
                type="submit"
                id="code"
                disabled={!code.valid}
                value="Zaloguj"
              />
            </div>
            <div className="sub-text">
              Jeśli nie dostałeś wiadomości z kodem, skontaktuj się z nami, nasze numery znajdziesz na zaproszeniu.
            </div>
          </form>
          }

        </div>
      </div>
    </div>
  );
}


export default LoginPage
