import React from 'react'
import "./phone-input.css"


function addSpacesAfter(no, pl) {
  return no.split('').reduce((out, v, k) => {
    if (pl.includes(k + 1) && (k + 1) < no.length) {
      return out + v + ' '
    }
    return out + v
  }, '')
}


function check(no) {
  no = no.replace(/[\D]/g, '')
  no = no.replace(/^(\d{2})0*(.*)$/, '$1$2')
  if ((no.startsWith('44', 2) || no.startsWith('48', 2)) && no.length > 4) {
    no = no.substring(2, no.length)
  }
  no = no.replace(/^(\d{2})0*(.*)$/, '$1$2')
  let valid = false
  if (no.startsWith('44')) {
    no = addSpacesAfter(no, [2, 6, 9])
    no = no.substring(0, 15)
    valid = no.length === 15
  } else {
    no = addSpacesAfter(no, [2, 5, 8])
    no = no.substring(0, 14)
    valid = no.length === 14
  }
  no = '+' + no
  let raw = no.replace(/ /g, '')
  return {no, valid, raw}
}

function PhoneInput({phone, onChange, disabled, name}) {

  function handlePhoneUpdate(event) {
    let no = event.target.value
    onChange(check(no))
  }

  return (
    <input
      className="PhoneInput"
      value={phone.no}
      onChange={handlePhoneUpdate}
      disabled={disabled}
      name={name}
      id={name}
      type="tel"
      required
    />
  );
}


export default PhoneInput

export {check}
