import axios from "axios"

const baseURL = process.env.NODE_ENV === "production" ? "https://mdapi.kamilaibartek.pl/" : "http://localhost:3000/"

let instance = axios.create({baseURL})

function saveToken(token) {
  instance = axios.create({
    baseURL,
    headers: {
      'Authorization' : token
    }
  })
}

function removeToken() {
  instance = axios.create({
    baseURL
  })
}

// Auth
function sendTextToken (phoneNo) {
  return instance.post('/auth/textCode', {phoneNo})
}

function login (phoneNo, token) {
  return instance.post('/auth/login', {phoneNo, token}).then(resp => resp.data.token)
}

// Basic
function getMe () {
  return instance.get('/me').then(resp => resp.data)
}

function getMyInvite () {
  return instance.get('/invite').then(resp => resp.data)
}

function getMyRSVP () {
  return instance.get('/rsvp').then(resp => resp.data)
}

function saveMyRSVP (rsvps) {
  return instance.post('/rsvp', {rsvps})
}

// Tunes
function getTunes () {
  return instance.get('/tunes').then(resp => resp.data)
}

function createTune (tune) {
  return instance.post('/tunes', {tune})
}

function createTuneVote (tuneId) {
  return instance.post(`/tunes/${tuneId}/vote`, {})
}

function removeTuneVote (tuneId) {
  return instance.delete(`/tunes/${tuneId}/vote`)
}

// Uploads
function getUploads(type) {
  return instance.get(`/uploads/?type=${type}`).then(resp => resp.data)
}

function createUpload (upload) {
  return instance.post(`/uploads`, {upload}).then(resp => resp.data)
}

function getUpload(uploadId) {
  return instance.get(`/uploads/${uploadId}`).then(resp => resp.data)
}

function deleteUpload(uploadId) {
  return instance.delete(`/uploads/${uploadId}`)
}

function approveUpload(uploadId) {
  return instance.post(`/uploads/${uploadId}/approve`)
}

function declineUpload(uploadId) {
  return instance.post(`/uploads/${uploadId}/decline`)
}


export {
  saveToken, removeToken,
  sendTextToken, login,
  getMe, getMyInvite, getMyRSVP, saveMyRSVP,
  getTunes, createTune, createTuneVote, removeTuneVote,

  getUploads, createUpload, getUpload, deleteUpload, approveUpload, declineUpload
}
