import React, { useState, useEffect, useCallback } from "react"
import LoginPage from '../login-page'
import HomePage from '../home-page'
import {saveToken, removeToken, getMe} from '../../api'
import LoadingBox from "../loading-box"


function App() {
  const [me, setMe] = useState(null)
  const [loading, setLoading] = useState(true)

  const logout = useCallback(() => {
    removeToken()
    window.localStorage.removeItem("authToken")
    setMe(null)
  }, [])

  const newToken = useCallback((token) => {
    setLoading(true)
    window.localStorage.setItem("authToken", token)
    saveToken(token)
    getMe(token).then(me => {
      setMe(me)
    }).catch(logout).finally(() => {
      setLoading(false)
    })
  }, [logout])

  useEffect(() => {
    if(!me) {
      const token = window.localStorage.getItem('authToken') || null
      if (token) {
        newToken(token)
      } else {
        setLoading(false)
      }
    }
  }, [me, newToken])


  return (
    <div className="App">
      {loading && <LoadingBox/>}
      {me && <HomePage logout={logout} me={me}/>}
      {!me && <LoginPage newToken={newToken}/>}
    </div>
  );
}

export default App
