import React, { useCallback, useEffect, useState } from "react"
import './gallery.scss'
import Uploader from "./uploader"
import {getUploads, deleteUpload, approveUpload, declineUpload} from "../../api"
import up from "./icons8-thumbs-up-50.png"
import down from "./icons8-thumbs-down-50.png"
import deleteIcon from "./icons8-empty-trash-100.png"
import closeIcon from "./icons8-delete-100.png"
import downloadIcon from "./icons8-download-from-cloud-100.png"
import nextIcon from "./icons8-next-page-100.png"
import prevIcon from "./icons8-back-to-100.png"

const baseURL = process.env.NODE_ENV === "production" ? "https://uploads.kamilaibartek.pl/uploads" : "https://dev-uploads.kamilaibartek.pl/uploads"

function Gallery({me}) {
  const [type, setType] = useState("all")
  const [all, setAll] = useState([])
  const [changed, setChange] = useState(0)

  const [key, setKey] = useState(null)

  useEffect(() => {
    getUploads(type).then(setAll)
  }, [type, changed])


  function approve(id) {
    approveUpload(id).then(() => {
      setChange(changed + 1)
    })
  }

  function decline(id) {
    declineUpload(id).then(() => {
      setChange(changed + 1)
    })
  }

  function del(id) {
    deleteUpload(id).then(() => {
      setChange(changed + 1)
      close()
    })
  }

  function open(key) {
    setKey({id: key})
  }

  function close() {
    setKey(null)
  }

  const next = useCallback((e) => {
    if (key.id < all.length - 1) {
      setKey({id: key.id + 1})
    }
  }, [key, all.length])

  const prev = useCallback((e) => {
    if (key.id > 0) {
      setKey({id: key.id - 1})
    }
  }, [key])

  const handleOnKeyDown = useCallback((e) => {
    if (!key) {
      return
    }
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      next()
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      prev()
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      close()
    }
  }, [key, next, prev])

  function buildUrl(photo, version) {
    return `${baseURL}/${version}/${photo.id}.${photo.extension}`
  }

  function download(photo) {
    if (photo) {
      window.open(buildUrl(photo, 'org'), "_blank");
    } else {
      window.open(buildUrl(all[key.id], 'org'), "_blank");
    }
  }


  useEffect(() => {
    document.addEventListener("keydown", handleOnKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleOnKeyDown, false);
    }
  }, [key, handleOnKeyDown])

  return (
    <div className="Gallery" onKeyDown={handleOnKeyDown}>
      <h2>GALERIA ZDJĘĆ</h2>
      <div className="heart-divider">
        <span className="grey-line"/>
        <i className="icon-heart pink-heart"/>
        <i className="icon-heart grey-heart"/>
        <span className="grey-line"/>
      </div>
      <div className="text">Wielu z Was uwieczniło ten dzień na&nbsp;zdjęciach zrobionych prywatnymi aparatami.
        Checmy poprosić Was o&nbsp;podzielenie się&nbsp;z&nbsp;nami Waszymi zdjęciami i&nbsp;filmami. W&nbsp;tym miejscu
        możesz załadować swoje pliki,
        zobaczyć zdjęcia zrobione przez innych gości jak również zdjęcia zrobione przez naszego fotografa.
      </div>
      <Uploader/>

      {!!key && <div className="FullScreen">
        <img src={buildUrl(all[key.id], 'org')} className="main" alt=""/>
        <img src={closeIcon} className="close" onClick={close} alt="Zamknij"/>}
        {key.id < (all.length - 1) && <div className="next" onClick={next}>
          <img src={nextIcon} alt="Następne"/>
        </div>}
        {key.id > 0 && <div className="prev" onClick={prev}>
          <img src={prevIcon} alt="Poprzednie"/>
        </div>}
        <div className="buttons">
          {me.id === all[key.id].user_id && <img src={deleteIcon} className="delete" onClick={(e) => {
            e.stopPropagation();
            del(all[key.id].id)
          }} alt="Usuń"/>}
          <img src={downloadIcon} className="download" onClick={download} alt="Pobierz"/>}
        </div>
        <p className='name'>{all[key.id].name}</p>

      </div>}
      <div className="text GalleryMenu">
        <div className={type === "all" ? "active" : ""} onClick={() => setType("all")}>Wszystkie Zdjęcia</div>
        <div className={type === "photographers" ? "active" : ""} onClick={() => setType("photographers")}>Zdjęcia fotografa</div>
        <div className={type === "my" ? "active" : ""} onClick={() => setType("my")}>Twoje Zdjęcia</div>
      </div>
      {!!me.isAdmin && <div className="text GalleryMenu">
        <div className={type === "notApproved" ? "active" : ""} onClick={() => setType("notApproved")}>Nie zatwierdzone</div>
        <div className={type === "notProcessed" ? "active" : ""} onClick={() => setType("notProcessed")}>Nie przetworzone</div>
        <div className={type === "deleted" ? "active" : ""} onClick={() => setType("deleted")}>Usunięte</div>
      </div>}

      {type !== "notProcessed" ?
        <div className="Photos">
          {all.map((photo, key) =>
            <div className="imgBox" key={photo.id} onClick={() => open(key)}>
              <img src={buildUrl(photo, 'min')} alt=""/>
              <div>
                <p>{photo.name}</p>
                {!!me.isAdmin && (type === "notApproved" ?
                  <img src={up} onClick={(e) => {
                    e.stopPropagation();
                    approve(photo.id)
                  }} alt="Approve"/>
                  : <img src={down} onClick={(e) => {
                    e.stopPropagation();
                    decline(photo.id)
                  }} alt="Decline"/>)}
              </div>
            </div>
          )}

          {all.length % 3 === 2 && <div className="imgBox fake" key="extra"/>}
        </div>
        :
        <div className="text uploads">
          {all.map((photo, key) =>
            <p key={photo.id} onClick={() => download(photo)}>{photo.name}: {photo.org_name}</p>
          )}
        </div>
      }


    </div>
  )


}

export default Gallery
