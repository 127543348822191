import React, {useState, useEffect} from 'react'
import {createUpload, deleteUpload} from "../../api"
import Dropzone from "react-dropzone"
import axios from "axios"
import {cloneDeep} from "lodash"
import './uploader.scss'
import upload from "./icons8-upload-to-cloud-100.png"

const instance = axios.create()

function Uploader() {

  const [allFiles, setFiles] = useState([])
  const [statuses, setStatuses] = useState({})

  useEffect(() => {
    (async ()=>{
      let changes = false
      for (let file of allFiles) {
        if (!file.id) {
          changes = true
          let name = file.file.name
          let type = file.file.type
          let {id, url} = await createUpload({orgName: name, fileType: type})
          file.id = id
          let options = {
            headers: {
              'Content-Type': type,
            },
            onUploadProgress: (event) => {
              statuses[id] = Math.ceil(event.loaded / event.total * 100) + "%"
              setStatuses(cloneDeep(statuses))
            }
          }
          const reader = new FileReader()
          reader.onabort = () => deleteUpload(id)
          reader.onerror = () => deleteUpload(id)
          reader.onload = () => {
            return instance.put(url, reader.result, options).then(()=>{
              statuses[id] = 'OK'
              setStatuses(cloneDeep(statuses))
            }).catch(()=>{
              statuses[id] = 'BŁĄD'
              setStatuses(cloneDeep(statuses))
            })
          }
          reader.readAsArrayBuffer(file.file)
        }
      }
      if (changes) {
        setFiles(cloneDeep(allFiles))
      }
    })()
  }, [allFiles, statuses])

  function addFiles(acceptedFiles) {
    const newFiles = acceptedFiles.map(file => ({
      file,
      progress: 0
    }))
    setFiles(cloneDeep(allFiles.concat(newFiles)))
  }

  return (
    <div className="Uploader">

          <Dropzone onDrop={addFiles} accept="image/*,video/*">
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps({className: "DropZone"})}>
                  <input {...getInputProps()} />
                  <img src={upload} alt="Upload"/>
                  <p>Przenieś i upuść swoje pliki tutaj,<br/> lub kliknij aby je wybrać</p>
                </div>
              </section>
            )}
          </Dropzone>
        { !!allFiles.length &&
        <React.Fragment>
            <div className="text">Dziękujemy za Twoje zdjęcia oraz filmy!! Za chwhile twoje zdjęcia będą widoczne w galerii poniżej. Twoje piliki z filmami zostały zapisane poprawnie, natopmiast nadal pracujemy nad ich wyświetleniem.</div>
            <div className="text uploads">
              {allFiles.map((file, k) => <p key={k} className={statuses[file.id]==="OK" ? "green" : (statuses[file.id]==="ERROR" ? "red" : "")}>{file.file.name} {statuses[file.id]}</p>)}
            </div>
        </React.Fragment>
        }
    </div>
  )
}

export default Uploader
