import React, {useState, useEffect} from 'react'


const END_DATE = new Date('2019-07-26T13:00:00.000Z')

function Counter() {
  const [date, setDate] = useState(new Date())

  function tick() {
    setDate(new Date())
  }

  useEffect(() => {
    let intervalId = setInterval(tick, 500);
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  let diff = date - END_DATE
  diff = diff > 0 ? diff : 0;

  let s = Math.floor((diff / 1000) % 60)
  let m = Math.floor((diff / 1000 / 60) % 60)
  let h = Math.floor((diff / 1000 / 60 / 60) % 24)
  let d = Math.floor((diff / 1000 / 60 / 60 / 24) % 7)
  let w = Math.floor(diff / 1000 / 60 / 60 / 24 / 7)


  return (
    <div className="Counter">
      <span>{w}T</span>
      <span>{d}D</span>
      <span>{h}G</span>
      <span>{m}M</span>
      <span>{s}S</span>
    </div>
  )
}


export default Counter
