import React from 'react'
import './loading-box.scss'

function LoadingBox() {
  return (
    <div className="LoadingBox">
      <div className="spinner">
        <div className="bounce1"/>
        <div className="bounce2"/>
        <div className="bounce3"/>
      </div>
    </div>
  )
}

export default LoadingBox