import React from 'react'

import './home-page.scss'

import Gallery from "../gallery"
import kib from "../main-page/kamilaibartek.jpg"
import Counter from "../counter"


function HomePage({logout, me}) {

  return (
    <div className="HomePage">
          <div className="top-box">
            <div onClick={logout} className="logout-box">WYLOGUJ</div>
            <div className="title">
              <div className="lines">
                <h1>KAMILA + BARTEK</h1>
              </div>
              <h4>- 26 LIPEC 2019 -</h4>
            </div>
          </div>
        <Gallery me={me}/>
        <div className="clock-box">
          <div className="wrap">
            <h2>Minęło już</h2>
            <div className="heart-divider">
              <span className="white-line"/>
              <i className="icon-heart pink-heart"/>
              <i className="icon-heart white-heart"/>
              <span className="white-line"/>
            </div>
            <Counter/>
          </div>
        </div>
        <div className="about-us-box">
          <h2>MŁODA PARA</h2>
          <div className="heart-divider">
            <span className="grey-line"/>
            <i className="icon-heart pink-heart"/>
            <i className="icon-heart grey-heart"/>
            <span className="grey-line"/>
          </div>
          <div className="container">
            <div className="white">
              <img src={kib} alt="Kamila i Bartek"/>
            </div>
            <div className="text">
              Poznaliśmy się już ponad 11 lat temu zaczynając pierwszą klasę liceum, w naszym ukochanym "PIKu" w Katowicach. Kamila wpadła Bartkowi w&nbsp;oko od samego początku, lecz na tym etapie zupełnie nic z tego nie wyszło. Po trzech latach Kamila zaczęła studia w&nbsp;Katowicach, a&nbsp;Bartek w&nbsp;Londynie i tak nasze drogi się&nbsp;rozeszły. Przez kolejne cztery lata widywaliśmy się&nbsp;sporadycznie na&nbsp;spotkaniach klasowych wraz ze&nbsp;znajomymi z&nbsp;liceum. Po&nbsp;pewnym czasie nasze spotkania zaczęły być coraz częstsze, aż do września 2014 roku kiedy to tuż przed ostatnim rokiem studiów zostaliśmy parą. Nasz pierwszy rok był wymagającym czasem - bycie razem lecz osobno dało nam popalić. Po roku rozłąki a zarazem końcu studiów zamieszkaliśmy razem w&nbsp;Londynie, gdzie do dziś mieszkamy i&nbsp;pracujemy. Pewnego lipcowego poranka, będąc na&nbsp;urlopie w&nbsp;Katowicach, Bartek wpadł na&nbsp;pomysł że&nbsp;to&nbsp;właśnie ten dzień -&nbsp;to&nbsp;dzień, w&nbsp;którym chciałby się oświadczyć! Tego samego dnia pobiegł do sklepu, kupił pierścionek i oświadczył się w tym samym parku gdzie nasza historia się zaczęła. I tak w przeddzień naszej drugiej rocznicy zaręczyn odbędzie się nasz ślub, na&nbsp;który mamy zaszczyt serdecznie was zaprosić.
            </div>
          </div>
        </div>

        <div className="play-box">
          <div className="contents">
            <h2>Dziękujemy!</h2>
            <div className="heart-divider">
              <span className="white-line"/>
              <i className="icon-heart pink-heart"/>
              <i className="icon-heart white-heart"/>
              <span className="white-line"/>
            </div>
            <div className="names">KAMILA I BARTEK</div>
          </div>
        </div>
    </div>
  )
}

export default HomePage
